<template>
  <div class="home"></div>
  <template v-if="isMobile">
    <NavbarMobile  @routeClick="changeRoute" />

  </template>
</template>

<script>
import NavbarMobile from "@/components/NavbarMobile.vue";

export default {
  name: "Home",
  components: {NavbarMobile},
  created() {},
  mounted() {
    this.checkMobile();
  },

  data() {
    return{
      isMobile: true,
    }
  },

  methods: {
    checkMobile() {
      this.isMobile = window.innerWidth <= 700 && (
          this.$store.state.me.user.email === 'info@marcocorver.nl'
          || this.$store.state.me.user.email === 'maurice@consultinvest.nl'
          || this.$store.state.me.user.email === 'jim.harders@gmail.com'

      );
    },
    changeRoute({ navItem, index, oldIndex }) {
      this.direction = index < oldIndex ? "to-right" : "to-left";
      this.$nextTick(() => this.$router.push(navItem.path));
    },
  }
};
</script>
